import Vue from 'vue';
import router from './router';

import VueAppInsights from 'vue-application-insights';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let aiKey = '';
if (process.env.NODE_ENV === 'production') {
  aiKey = '44676871-c92f-4f69-b176-90f3ad38b8d5';
}
if (process.env.NODE_ENV !== 'production') {
  aiKey = '42979eb3-8908-4346-8f00-1ce3519f8888';
}

const ai = new ApplicationInsights({   
  config: {   
    instrumentationKey: aiKey,
    enableCorsCorrelation: true,
  },
});
ai.loadAppInsights();
ai.context.application.ver = process.env.NODE_ENV === 'production' ? '20250214.408.main user ' : 'LOCAL';

Vue.use(VueAppInsights, {
  baseName: 'TDS SPA',
  appInsights: ai,
  id: aiKey,
  router,
  trackInitialPageView: true,
});

export default ai;
