// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
// eslint-disable @typescript-eslint/no-unused-vars
import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import * as Domain from '../models/Domain';
import store from '../plugins/vuex';
import * as StudioModuleDomain from '@/models/StudioModuleDomain';
import * as PersistanceHelper from '@/helpers/PersistanceHelper';
import Services from '@/services/Services';
import * as ToDoHelper from '@/helpers/ToDoHelper';
import * as StudioHelper from '@/helpers/StudioHelper';

@Module({ dynamic: true, store, namespaced: true, name: 'studio' })
export default class StudioModule extends VuexModule {
  public studios: Map<string, Domain.Studio> = new Map<string, Domain.Studio>();
  public studiosArr: Domain.Studio[] = []; // todovue3 remove this when vuejs supports map reactivity
  public toDos: Map<string, Domain.ToDo> = new Map<string, Domain.ToDo>();
  public toDosArr: Domain.ToDo[] = []; // todovue3 remove this when vuejs supports map reactivity
  public derivedChangeCount: number  = 0;

  @Mutation
  public restore(payload: StudioModuleDomain.RestoreMutation): void {
    const userId : string = store.state.authentication.user?.id as string;
    try {
      payload.studios.forEach((item) => {
        const matched = this.studios.get(item.id);
        if (matched) {
          const etagsAreDifferentAndObjectsChanged = matched.etag !== item.etag; // find if PersistenceHelper.Update will change something, must be before the call
          PersistanceHelper.Update(matched, item, `Studio ${item.id}`);
          if (etagsAreDifferentAndObjectsChanged === true) {
            StudioHelper.computeDerivedFields(this.studios.get(item.id) as Domain.Studio, userId);
            this.derivedChangeCount += 1;
          }
        } else {
          this.studios.set(item.id, PersistanceHelper.Update(null, item, `Studio ${item.id}`));
          this.studiosArr.push(this.studios.get(item.id) as Domain.Studio);
          StudioHelper.computeDerivedFields(this.studios.get(item.id) as Domain.Studio, userId);
          this.derivedChangeCount += 1;
        }
      });
      payload.toDos.forEach((item) => {
        const matched = this.toDos.get(item.id);
        if (matched) {
          const etagsAreDifferentAndObjectsChanged = matched.etag !== item.etag; // find if PersistenceHelper.Update will change something, must be before the call
          PersistanceHelper.Update(matched, item, `ToDo ${item.id}`);
          if (etagsAreDifferentAndObjectsChanged === true) {
            ToDoHelper.computeDerivedFields(this.studios.get(matched.studioId) as Domain.Studio, matched, userId);
            this.derivedChangeCount += 1;
          }
        } else {
          this.toDos.set(item.id, PersistanceHelper.Update(null, item, `ToDo ${item.id}`));
          this.toDosArr.push(this.toDos.get(item.id) as Domain.ToDo);
          ToDoHelper.computeDerivedFields(this.studios.get(item.studioId) as Domain.Studio, this.toDos.get(item.id) as Domain.ToDo, userId);
          this.derivedChangeCount += 1;
        }
      });
      payload.removedStudioIds.forEach((item) => {
        this.studios.delete(item);

        const toRemove = this.studiosArr.find((t) => t.id === item);
        if (toRemove !== undefined) {
          this.studiosArr.splice(this.studiosArr.indexOf(toRemove), 1);
        }
      });
      payload.removedToDoIds.forEach((item) => {
        this.toDos.delete(item);

        const toRemove = this.toDosArr.find((t) => t.id === item);
        if (toRemove !== undefined) {
          this.toDosArr.splice(this.toDosArr.indexOf(toRemove), 1);
        }
      });
    } catch (err: any) {
      Services.LogException(err);
      payload.out_error = true;
    }
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public reset(payload: StudioModuleDomain.ResetMutation): void {
    this.studios.forEach((value) => {
      payload.metadata_deletes.push(value);
    });
    this.studios.clear();
    while (this.studiosArr.length !== 0) {
      this.studiosArr.pop();
    }

    this.toDos.forEach((value) => {
      payload.metadata_deletes.push(value);
    });
    this.toDos.clear();
    while (this.toDosArr.length !== 0) {
      this.toDosArr.pop();
    }
  }

  @Mutation
  public createTeamStudio(payload: StudioModuleDomain.CreateTeamStudioMutation): void {
    const studio: Domain.Studio = new Domain.TeamStudio(payload.studioId, payload.now, payload.userId, payload.tags, payload.title, payload.color, payload.purpose, payload.isSolo);

    // add teammates friends in auto-mode
    if (payload.userId.endsWith('-*')) {
      store.state.user.users.forEach((value) => {
        const teammateF = new Domain.Teammate(value.id, payload.now);
        studio.teammates.set(teammateF.userId, teammateF);
      });
    }

    this.studios.set(studio.id, studio);
    this.studiosArr.push(studio);
    StudioHelper.computeDerivedFields(studio, payload.userId);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public deleteStudio(payload: StudioModuleDomain.DeleteStudioMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    // remove all todos in this studio
    const toDoIdsToDelete: string[] = [];
    this.toDos.forEach((value, key) => {
      if (value.studioId === studio.id) {
        toDoIdsToDelete.push(value.id);
      }
    });
    toDoIdsToDelete.forEach((item) => {
      this.toDos.delete(item);

      const toRemove = this.toDosArr.find((t) => t.id === item);
      if (toRemove !== undefined) {
        this.toDosArr.splice(this.toDosArr.indexOf(toRemove), 1);
      }
    });

    this.studios.delete(studio.id);
    const toRemove = this.studiosArr.find((t) => t.id === studio.id);
    if (toRemove !== undefined) {
      this.studiosArr.splice(this.studiosArr.indexOf(toRemove), 1);
    }

    this.derivedChangeCount += 1;

    payload.metadata_deletes.push(studio);
  }

  @Mutation
  public changeStudioTitle(payload: StudioModuleDomain.ChangeStudioTitleMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    studio.title = payload.newTitle;

    const action = new Domain.StudioTitleChanged(payload.actionId, payload.now, payload.userId, payload.oldTitle, payload.newTitle);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public changeStudioColor(payload: StudioModuleDomain.ChangeStudioColorMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    studio.color = payload.newColor;

    const action = new Domain.StudioColorChanged(payload.actionId, payload.now, payload.userId, payload.oldColor, payload.newColor);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public changeStudioPurpose(payload: StudioModuleDomain.ChangeStudioPurposeMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    studio.purpose = payload.newPurpose;

    const action = new Domain.StudioPurposeChanged(payload.actionId, payload.now, payload.userId, payload.oldPurpose, payload.newPurpose);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public changeStudioTags(payload: StudioModuleDomain.ChangeStudioTagsMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    studio.tags = new Map(payload.newTags.map(tag => [tag.id, tag]));

    const oldTagsString = payload.oldTags.map(tag => tag.value).join(', ');
    const newTagsString = payload.newTags.map(tag => tag.value).join(', ');

    const action = new Domain.StudioTagsChanged(payload.actionId, payload.now, payload.userId, oldTagsString, newTagsString);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public changeStudioSoloStatus(payload: StudioModuleDomain.ChangeStudioSoloStatusMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    studio.isSolo = payload.isSolo;

    const action = new Domain.StudioSoloStatusChanged(payload.actionId, payload.now, payload.userId, payload.isSolo);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public updateToDoTemplate(payload: StudioModuleDomain.UpdateToDoTemplateMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    let toDoTemplate = studio.toDoTemplates.get(payload.toDoTemplateId);
    if (toDoTemplate === undefined) {
      toDoTemplate = new Domain.ToDoTemplate(payload.toDoTemplateId, payload.now, payload.userId, payload.name);
      studio.toDoTemplates.set(toDoTemplate.id, toDoTemplate);
    }
    else
    {
      toDoTemplate.name = payload.name;
      toDoTemplate.lastUpdateInstant = payload.now;
      toDoTemplate.lastUpdateUserId = payload.userId;
    }

    toDoTemplate.items = new Map(payload.items.map(item => [item.id, item]));

    StudioHelper.computeDerivedFields(studio, payload.userId);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public openStudio(payload: StudioModuleDomain.OpenStudioMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    studio.closedInstant = null;

    const action = new Domain.StudioOpened(payload.actionId, payload.now, payload.userId, payload.reason);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public closeStudio(payload: StudioModuleDomain.CloseStudioMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    studio.closedInstant = payload.now;

    const action = new Domain.StudioClosed(payload.actionId, payload.now, payload.userId, payload.reason);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public createStudioInvitation(payload: StudioModuleDomain.CreateStudioInvitationMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    // Action
    const action = new Domain.StudioInvitationCreated(payload.actionId, payload.now, payload.userId, payload.invitationId);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public processStudioInvitation(payload: StudioModuleDomain.ProcessStudioInvitationMutation): void {
    // No client-side implementation for this mutation
  }

  @Mutation
  public inviteStudioTeammate(payload: StudioModuleDomain.InviteStudioTeammateMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    // Action
    const action = new Domain.StudioTeammateInvited(payload.actionId, payload.now, payload.userId, payload.inviteeUserId);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public leaveStudio(payload: StudioModuleDomain.LeaveStudioMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    const teammate = studio.teammates.get(payload.userId);
    if (teammate === undefined) {
      throw new Error('From leaveStudio in StudioModule.ts: LeaveStudioMutation.userId does not exist in teammates collection');
    } else {
      teammate.departure = new Domain.StudioDeparture(payload.now, Domain.EnumTeammateDepartureReason.left, payload.reason);
    }

    // Action
    const action = new Domain.StudioTeammateLeft(payload.actionId, payload.now, payload.userId, payload.reason);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public removeStudioTeammate(payload: StudioModuleDomain.RemoveStudioTeammateMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) {throw new Error();}

    const teammate = studio.teammates.get(payload.teammateUserId);
    if (teammate === undefined) {
      throw new Error('From studioTeammateRemoved in StudioModule.ts: RemoveStudioTeammateMutation.teammateUserId does not exist in teammates collection');
    } else {
      teammate.departure = new Domain.StudioDeparture(payload.now, Domain.EnumTeammateDepartureReason.removed, payload.reason, payload.userId);
    }

    // Action
    const action = new Domain.StudioTeammateRemoved(payload.actionId, payload.now, payload.userId, payload.teammateUserId, payload.reason);
    studio.actions.set(action.id, action);
    StudioHelper.computeDerivedFields(studio, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(studio);
  }

  @Mutation
  public createTask(payload: StudioModuleDomain.CreateTaskMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo: Domain.ToDo = new Domain.Task(payload.toDoId, payload.now, payload.userId, payload.studioId, payload.tags, payload.title, payload.description, payload.ownerUserId);
    toDo.startedInstant = payload.startedInstant !== null ? payload.startedInstant : payload.recurrence !== '' ? payload.now : null;
    toDo.recurrence = payload.recurrence;
    toDo.effort = payload.effort;
    toDo.urgency = payload.urgency;
    toDo.importance = payload.importance;
    toDo.dueInstant = payload.dueInstant;

    for (const prerequisite of payload.prerequisites) {
      const publisherToDo = this.toDos.get(prerequisite.publisherToDoId);
      if (publisherToDo === undefined) { throw new Error(); }
      publisherToDo.subscriberToDoIds.push(toDo.id);
      payload.metadata_upserts.push(publisherToDo);
      toDo.prerequisites.set(prerequisite.id, prerequisite);
    }

    this.toDos.set(toDo.id, toDo);
    this.toDosArr.push(toDo);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);

  }

  @Mutation
  public deleteToDo(payload: StudioModuleDomain.DeleteToDoMutation): void {
    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) {throw new Error();}

    this.toDos.delete(toDo.id);
    const toRemove = this.toDosArr.find((t) => t.id === toDo.id);
    if (toRemove !== undefined) {
      this.toDosArr.splice(this.toDosArr.indexOf(toRemove), 1);
    }

    this.derivedChangeCount += 1;

    payload.metadata_deletes.push(toDo);
  }

  @Mutation
  public cancelToDo(payload: StudioModuleDomain.CancelToDoMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    toDo.cancellationInstant = payload.now;

    const messageTargetUserIds = Array.from(studio.teammates.values()).filter((item) => (item.departure === null) && (item.userId !== payload.userId)).map((item) => item.userId);
    const action: Domain.ToDoCanceled = new Domain.ToDoCanceled(payload.actionId, payload.now, payload.userId, payload.messageBody, messageTargetUserIds);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public restoreToDo(payload: StudioModuleDomain.RestoreToDoMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    toDo.cancellationInstant = null;

    const messageTargetUserIds = Array.from(studio.teammates.values()).filter((item) => (item.departure === null) && (item.userId !== payload.userId)).map((item) => item.userId);
    const action: Domain.ToDoRestored = new Domain.ToDoRestored(payload.actionId, payload.now, payload.userId, payload.messageBody, messageTargetUserIds);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoOwner(payload: StudioModuleDomain.ChangeToDoOwnerMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldOwnerUserId = toDo.ownerUserId;

    toDo.ownerUserId = payload.newOwnerUserId;

    const action = new Domain.ToDoOwnerChanged(payload.actionId, payload.now, payload.userId, oldOwnerUserId, payload.newOwnerUserId);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoTitle(payload: StudioModuleDomain.ChangeToDoTitleMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldTitle = toDo.title;

    toDo.title = payload.newTitle;

    const action = new Domain.ToDoTitleChanged(payload.actionId, payload.now, payload.userId, oldTitle, payload.newTitle);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoDescription(payload: StudioModuleDomain.ChangeToDoDescriptionMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldDescription = toDo.description;

    toDo.description = payload.newDescription;

    const action = new Domain.ToDoDescriptionChanged(payload.actionId, payload.now, payload.userId, oldDescription, payload.newDescription);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoEffort(payload: StudioModuleDomain.ChangeToDoEffortMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldEffort = toDo.effort.toString();

    toDo.effort = payload.newEffort;

    const action = new Domain.ToDoEffortChanged(payload.actionId, payload.now, payload.userId, oldEffort, payload.newEffort);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoUrgency(payload: StudioModuleDomain.ChangeToDoUrgencyMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldUrgency = toDo.urgency.toString();

    toDo.urgency = payload.newUrgency;

    const action = new Domain.ToDoUrgencyChanged(payload.actionId, payload.now, payload.userId, oldUrgency, payload.newUrgency);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoImportance(payload: StudioModuleDomain.ChangeToDoImportanceMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldImportance = toDo.importance.toString();

    toDo.importance = payload.newImportance;

    const action = new Domain.ToDoImportanceChanged(payload.actionId, payload.now, payload.userId, oldImportance, payload.newImportance);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoDueInstant(payload: StudioModuleDomain.ChangeToDoDueInstantMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldDueInstant = toDo.dueInstant;

    toDo.dueInstant = payload.newDueInstant;

    const action = new Domain.ToDoDueInstantChanged(payload.actionId, payload.now, payload.userId, oldDueInstant, payload.newDueInstant);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoRecurrence(payload: StudioModuleDomain.ChangeToDoRecurrenceMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is the before everything else
    const oldRecurrence = toDo.recurrence;

    toDo.recurrence = payload.newRecurrence;

    const action = new Domain.ToDoRecurrenceChanged(payload.actionId, payload.now, payload.userId, oldRecurrence, payload.newRecurrence);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public changeToDoPrerequisites(payload: StudioModuleDomain.ChangeToDoPrerequisitesMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Ensure this code is before rewriting prerequisites
    const oldPrerequisites = Array.from(toDo.prerequisites.values());
    for (const oldPrerequisite of oldPrerequisites) {
      if (payload.prerequisites.includes(oldPrerequisite) === false) {
        const publisherToDo = this.toDos.get(oldPrerequisite.publisherToDoId);
        if (publisherToDo === undefined) { throw new Error(); }
        publisherToDo.subscriberToDoIds = publisherToDo.subscriberToDoIds.filter((item) => item !== toDo.id);
        payload.metadata_upserts.push(publisherToDo);
      }
    }

    // rewriting prerequisites
    toDo.prerequisites.clear();
    for (const prerequisite of payload.prerequisites) {
      const publisherToDo = this.toDos.get(prerequisite.publisherToDoId);
      if (publisherToDo === undefined) { throw new Error(); }
      if (publisherToDo.subscriberToDoIds.includes(toDo.id) === false) {
        publisherToDo.subscriberToDoIds.push(toDo.id);
        payload.metadata_upserts.push(publisherToDo);
      }
      toDo.prerequisites.set(prerequisite.id, prerequisite);
    }

    const action = new Domain.ToDoPrerequisitesChanged(payload.actionId, payload.now, payload.userId, oldPrerequisites, payload.prerequisites);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public postpone(payload: StudioModuleDomain.PostponeMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    if ((payload.postponementInstant === null) && (payload.prerequisitesChangedId === null)) {
      let isTeammateCommitted = false;
      for (const userState of toDo.userStates.values()) {
        if (userState.userId === payload.userId) { continue; }
        if ((userState.lastInvolvement !== null) && (userState.lastInvolvement.type === 'Committed')) {
          isTeammateCommitted = true;
        }
      }
      if (!isTeammateCommitted) {
        toDo.startedInstant = null;
      }
    } else {
      // Activation
      toDo.startedInstant = toDo.startedInstant === null ? payload.now : toDo.startedInstant;
    }

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.lastMessageSeenInstant = payload.now;

    // Involvement
    const involvement: Domain.Postponed = new Domain.Postponed(payload.actionId, payload.now, payload.userId, payload.messageBody, payload.messageTargetUserIds, payload.postponementInstant);
    userState.lastInvolvement = involvement;
    toDo.actions.set(involvement.id, involvement);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, involvement);
    this.derivedChangeCount += 1;

    // Revert Completion
    toDo.completionInstant = null;

    // Postpone with Prerequisites - must be after the toDo is updated
    if ((payload.postponementInstant === null) && (payload.prerequisitesChangedId !== null)) {
      // Ensure this code is before rewriting prerequisites
      const oldPrerequisites = Array.from(toDo.prerequisites.values());
      for (const oldPrerequisite of oldPrerequisites) {
        if (payload.prerequisites.includes(oldPrerequisite) === false) {
          const publisherToDo = this.toDos.get(oldPrerequisite.publisherToDoId);
          if (publisherToDo === undefined) { throw new Error(); }
          publisherToDo.subscriberToDoIds = publisherToDo.subscriberToDoIds.filter((item) => item !== toDo.id);
          payload.metadata_upserts.push(publisherToDo);
        }
      }

      // rewriting prerequisites
      toDo.prerequisites.clear();
      for (const prerequisite of payload.prerequisites) {
        const publisherToDo = this.toDos.get(prerequisite.publisherToDoId);
        if (publisherToDo === undefined) { throw new Error(); }
        if (publisherToDo.subscriberToDoIds.includes(toDo.id) === false) {
          publisherToDo.subscriberToDoIds.push(toDo.id);
          payload.metadata_upserts.push(publisherToDo);
        }
        toDo.prerequisites.set(prerequisite.id, prerequisite);
      }

      const action = new Domain.ToDoPrerequisitesChanged(payload.actionId, payload.now, payload.userId, oldPrerequisites, payload.prerequisites);
      toDo.actions.set(action.id, action);
      // No need to.compute Derived Fields here, as it was already done above
    }

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public commit(payload: StudioModuleDomain.CommitMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Activation
    toDo.startedInstant = toDo.startedInstant === null ? payload.now : toDo.startedInstant;

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.lastMessageSeenInstant = payload.now;

    // Progress
    if (payload.progressInstant !== null) {
      const progress = new Domain.Progressed(payload.progressId, payload.now, payload.userId, payload.progressInstant, payload.progressSteps, payload.actionId);
      userState.lastProgress = progress;
      toDo.actions.set(progress.id, progress);
      ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, progress);
      this.derivedChangeCount += 1;

      for(const progressStep of payload.progressSteps) {
        const toDoStep = toDo.steps.get(progressStep.id) ?? null;
        if (toDoStep === null) {
          toDo.steps.set(progressStep.id, new Domain.Step(progressStep.id, payload.now, payload.userId, progressStep.title, progress));
        } else {
          toDoStep.progress = progress;
        }
      }
    }

    // Involvement
    const involvement: Domain.Committed = new Domain.Committed(payload.actionId, payload.now, payload.userId, payload.messageBody, payload.messageTargetUserIds, payload.commitmentInstant, payload.progressInstant !== null ? payload.progressId : null);
    userState.lastInvolvement = involvement;
    toDo.actions.set(involvement.id, involvement);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, involvement);
    this.derivedChangeCount += 1;

    // Revert Completion
    toDo.completionInstant = null;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public complete(payload: StudioModuleDomain.CompleteMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Activation
    toDo.startedInstant = toDo.startedInstant === null ? payload.now : toDo.startedInstant;

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.lastMessageSeenInstant = payload.now;

    // Progress
    if (payload.progressInstant !== null) {
      const progress = new Domain.Progressed(payload.progressId, payload.now, payload.userId, payload.progressInstant, payload.progressSteps, payload.actionId);
      userState.lastProgress = progress;
      toDo.actions.set(progress.id, progress);
      ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, progress);
      this.derivedChangeCount += 1;

      for(const progressStep of payload.progressSteps) {
        const toDoStep = toDo.steps.get(progressStep.id) ?? null;
        if (toDoStep === null) {
          toDo.steps.set(progressStep.id, new Domain.Step(progressStep.id, payload.now, payload.userId, progressStep.title, progress));
        } else {
          toDoStep.progress = progress;
        }
      }
    }

    // Involvement
    const involvement: Domain.Completed = new Domain.Completed(payload.actionId, payload.now, payload.userId, payload.messageBody, payload.messageTargetUserIds, payload.progressInstant !== null ? payload.progressId : null);
    userState.lastInvolvement = involvement;
    toDo.actions.set(involvement.id, involvement);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, involvement);
    this.derivedChangeCount += 1;

    // Temporary Completion - full transition is performed server-side
    toDo.completionInstant = payload.now;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public delegate(payload: StudioModuleDomain.DelegateMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.lastMessageSeenInstant = payload.now;

    // Involvement
    const involvement: Domain.Delegated = new Domain.Delegated(payload.actionId, payload.now, payload.userId, payload.messageBody, payload.messageTargetUserIds);
    userState.lastInvolvement = involvement;
    toDo.actions.set(involvement.id, involvement);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, involvement);
    this.derivedChangeCount += 1;

    // Revert Completion
    toDo.completionInstant = null;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public follow(payload: StudioModuleDomain.FollowMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.lastMessageSeenInstant = payload.now;

    // Involvement
    const involvement: Domain.Followed = new Domain.Followed(payload.actionId, payload.now, payload.userId, payload.messageBody, payload.messageTargetUserIds);
    userState.lastInvolvement = involvement;
    toDo.actions.set(involvement.id, involvement);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, involvement);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public withdraw(payload: StudioModuleDomain.WithdrawMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.lastMessageSeenInstant = payload.now;

    // Involvement
    const involvement: Domain.Withdrawn = new Domain.Withdrawn(payload.actionId, payload.now, payload.userId, payload.messageBody, payload.messageTargetUserIds);
    userState.lastInvolvement = involvement;
    toDo.actions.set(involvement.id, involvement);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, involvement);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public sendMessage(payload: StudioModuleDomain.SendMessageMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // Message
    const action: Domain.Messaged = new Domain.Messaged(payload.actionId, payload.now, payload.userId, payload.messageBody, payload.messageTargetUserIds);
    toDo.actions.set(action.id, action);
    ToDoHelper.computeDerivedFields(studio, toDo, payload.userId, action);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public acknowledgeMessage(payload: StudioModuleDomain.AcknowledgeMessageMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.lastMessageSeenInstant = payload.now;

    // increment change count
    ToDoHelper.incrementDerivedFields(studio, toDo);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public star(payload: StudioModuleDomain.StarMutation): void {
    const studio = this.studios.get(payload.studioId); // Get a reference to object
    if (studio === undefined) { throw new Error(); }

    const toDo = this.toDos.get(payload.toDoId); // Get a reference to object
    if (toDo === undefined) { throw new Error(); }

    // UserState
    let userState = toDo.userStates.get(payload.userId);
    if (userState === undefined) {
      userState = new Domain.ToDoUserState(payload.userId, toDo.creationInstant);
      toDo.userStates.set(userState.userId, userState);
    }
    userState.isStarred = payload.on;

    // increment change count
    ToDoHelper.incrementDerivedFields(studio, toDo);
    this.derivedChangeCount += 1;

    payload.metadata_upserts.push(toDo);
  }

  @Mutation
  public forceCompute(payload: StudioModuleDomain.ForceCompute): void {
    this.studios.forEach((value, key, map) => {
    });
    this.toDos.forEach((value, key, map) => {
    });
  }

}
